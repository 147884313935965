@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

body, html {
    --primary-color: #FFBD59;
    --secondary-color: #ff6c00;
    --text-color: #494949;
    --background-color: #ffffff;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Inter', sans-serif;
}

.blog-content {
    font-family: 'Inter', sans-serif !important;
}

.blog-content img {
    max-width: 80%;
    height: auto;
    display: block;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}


.blog-content a {
    color: #ff6c00 !important;
    text-decoration: none;
}

.blog-content a:hover {
    text-decoration: underline;
}

.blog-content p {
    font-size: 1rem;
    line-height: 1.6;
    color: #494949;
}

.blog-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #494949;
}

.blog-content h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #494949;
}

.blog-content h3 {
    font-size: 1.75rem;
    font-weight: bold;
    color: #494949;
}

.blog-content h4 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #494949;
}

.blog-content h5 {
    font-size: 1.25rem;
    font-weight: bold;
    color: #494949;
}

.blog-content h6 {
    font-size: 1rem;
    font-weight: bold;
    color: #494949;
}

